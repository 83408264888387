export default [
  {
    path: "/",
    name: "AI.Tana",
    component: () => import("../views/dashboard/index.vue"),
    meta: {
      title: "AI.TANA Health",
    }
  },
  {
    path: "/tour",
    name: "AI.Tana Tour",
    component: () => import("../views/dashboard/index2.vue"),
    meta: {
      title: "Dashboard",
    }
  },
  {
    path: "/test",
    name: "AI.Tana Tour2",
    component: () => import("../views/dashboard/tour.vue"),
    meta: {
      title: "Dashboard",
    }
  },
];